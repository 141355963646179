<template>
  <div
    class="row justify-center items-start bg-grey-3"
    style="min-height: calc(100vh - 83px);"
  >
    <q-card flat bordered style="width:1024px" class="q-mt-xl">
      <q-tabs dense v-model="selTab" class="bg-indigo-5 text-white">
        <q-tab
          v-for="(item, i) in listTab"
          :key="i"
          :name="item.name"
          :alert="item.change"
          :label="item.label"
        ></q-tab>
      </q-tabs>
      <q-form @submit="confirm = true">
        <q-tab-panels v-model="selTab" class="bg-indigo-1">
          <q-tab-panel name="siswa">
            <q-card-section class="column q-col-gutter-md q-pt-none">
              <p class="text-h6 text-grey q-mb-none">GENERAL :</p>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Jenjang</a>
                <q-select
                  :options="$LIST_JENJANG"
                  :disable="$route.params.id > 0"
                  outlined
                  v-model="siswaDetail.jenjang"
                  class="fill col-6"
                ></q-select>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Nama</a>
                <q-input
                  @change="listTab[0].change = true"
                  outlined
                  v-model="siswaDetail.nama"
                  class="fill col-6"
                  lazy-rules
                  :rules="[
                    (val) =>
                      (val && val.length > 0) || 'Nama Tidak Boleh Kosong',
                  ]"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">NIK</a>
                <q-input
                  @change="listTab[0].change = true"
                  outlined
                  v-model="siswaDetail.nik"
                  class="fill col-6"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">NISN</a>
                <q-input
                  @change="listTab[0].change = true"
                  outlined
                  v-model="siswaDetail.nisn"
                  class="fill col-6"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">NIPD</a>
                <q-input
                  @change="listTab[0].change = true"
                  outlined
                  v-model="siswaDetail.nipd"
                  class="fill col-6"
                ></q-input>
              </div>
              <div
                v-if="siswaDetail.jenjang == 'MA'"
                class="row items-center justify-between"
              >
                <a class="item col-6 text-left">Peminatan</a>
                <q-select
                  :options="minat"
                  @input="listTab[0].change = true"
                  outlined
                  v-model="siswaDetail.peminatan"
                  class="fill col-6"
                ></q-select>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Tempat Lahir</a>
                <q-input
                  @change="listTab[0].change = true"
                  outlined
                  v-model="siswaDetail.tempat_lahir"
                  class="fill col-6"
                  lazy-rules
                  :rules="[
                    (val) =>
                      (val && val.length > 0) ||
                      'Tempat Lahir Tidak Boleh Kosong',
                  ]"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Tanggal Lahir</a>
                <q-input
                  @change="listTab[0].change = true"
                  outlined
                  stack-label
                  label="Format: YYYY-MM-DD"
                  v-model="siswaDetail.tanggal_lahir"
                  class="fill col-6"
                  lazy-rules
                  :rules="[
                    (val) =>
                      (val && val.length > 0) ||
                      'Tanggal Lahir Tidak Boleh Kosong',
                  ]"
                >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        ref="qDateProxy"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date
                          v-model="siswaDetail.tanggal_lahir"
                          mask="YYYY-MM-DD"
                        >
                          <div class="row items-center justify-end">
                            <q-btn
                              v-close-popup
                              label="Close"
                              color="primary"
                              flat
                            />
                          </div>
                        </q-date>
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">gender</a>
                <q-select
                  @input="listTab[0].change = true"
                  outlined
                  v-model="siswaDetail.gender"
                  class="fill col-6"
                  lazy-rules
                  :options="['L', 'P']"
                  :rules="[
                    (val) =>
                      (val && val.length > 0) || 'Gender Tidak Boleh Kosong',
                  ]"
                ></q-select>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Tingkat</a>
                <q-select
                  @input="listTab[0].change = true"
                  outlined
                  v-model="siswaDetail.tingkat"
                  class="fill col-6"
                  lazy-rules
                  :options="
                    siswaDetail.jenjang == 'MA' ? tingkat_ma : tingkat_smp
                  "
                  :rules="[
                    (val) => (val && val > -1) || 'Tingkat Tidak Boleh Kosong',
                  ]"
                ></q-select>
              </div>
            </q-card-section>

            <q-card-section class="column q-col-gutter-md">
              <p class="text-h6 text-grey q-mb-none q-mt-md">
                Tempat Tinggal :
              </p>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">alamat</a>
                <q-input
                  @change="listTab[0].change = true"
                  outlined
                  v-model="siswaDetail.alamat"
                  class="fill col-6"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">rt</a>
                <q-input
                  @change="listTab[0].change = true"
                  outlined
                  v-model="siswaDetail.rt"
                  class="fill col-6"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">rw</a>
                <q-input
                  @change="listTab[0].change = true"
                  outlined
                  v-model="siswaDetail.rw"
                  class="fill col-6"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">dusun</a>
                <q-input
                  @change="listTab[0].change = true"
                  outlined
                  v-model="siswaDetail.dusun"
                  class="fill col-6"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Kelurahan</a>
                <q-input
                  @change="listTab[0].change = true"
                  outlined
                  v-model="siswaDetail.kelurahan"
                  class="fill col-6"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">kecamatan</a>
                <q-input
                  @change="listTab[0].change = true"
                  outlined
                  v-model="siswaDetail.kecamatan"
                  class="fill col-6"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Kabupaten</a>
                <q-input
                  @change="listTab[0].change = true"
                  outlined
                  v-model="siswaDetail.kabupaten"
                  class="fill col-6"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Provinsi</a>
                <q-input
                  @change="listTab[0].change = true"
                  outlined
                  v-model="siswaDetail.provinsi"
                  class="fill col-6"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Kode Pos</a>
                <q-input
                  @change="listTab[0].change = true"
                  outlined
                  v-model="siswaDetail.kode_pos"
                  class="fill col-6"
                ></q-input>
              </div>
            </q-card-section>
            <q-card-section class="column q-col-gutter-md ">
              <p class="text-h6 text-grey q-mb-none q-mt-md">Lain Lain :</p>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Sekolah Asal</a>
                <q-input
                  @change="listTab[0].change = true"
                  outlined
                  v-model="siswaDetail.sekolah_asal"
                  class="fill col-6"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Tipe Angkatan</a>
                <q-select
                  @input="listTab[0].change = true"
                  outlined
                  :options="optTipe"
                  v-model="siswaDetail.tipe_angkatan"
                  class="fill col-6"
                ></q-select>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Tahun Masuk</a>
                <q-input
                  @change="listTab[0].change = true"
                  outlined
                  v-model="siswaDetail.tahun_masuk"
                  class="fill col-6"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Jalur Sekolah Asal</a>
                <q-input
                  @change="listTab[0].change = true"
                  outlined
                  v-model="siswaDetail.jalur_sekolah_asal"
                  class="fill col-6"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">anak ke</a>
                <q-input
                  @change="listTab[0].change = true"
                  outlined
                  v-model="siswaDetail.anak_ke"
                  class="fill col-6"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Bersaudara</a>
                <q-input
                  @change="listTab[0].change = true"
                  outlined
                  v-model="siswaDetail.bersaudara"
                  class="fill col-6"
                ></q-input>
              </div>
            </q-card-section>
          </q-tab-panel>
          <q-tab-panel name="ayah">
            <q-card-section class="column q-col-gutter-md q-pt-none">
              <p class="text-h6 text-grey q-mb-none">GENERAL :</p>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Hubungan</a>
                <q-input
                  disable
                  outlined
                  v-model="ayahDetail.hubungan"
                  class="fill col-6"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Nama</a>
                <q-input
                  @change="listTab[1].change = true"
                  outlined
                  v-model="ayahDetail.nama"
                  class="fill col-6"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">NIK</a>
                <q-input
                  @change="listTab[1].change = true"
                  outlined
                  v-model="ayahDetail.nik"
                  class="fill col-6"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Tempat Lahir</a>
                <q-input
                  @change="listTab[1].change = true"
                  outlined
                  v-model="ayahDetail.tempat_lahir"
                  class="fill col-6"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Tanggal Lahir</a>
                <q-input
                  @change="listTab[1].change = true"
                  outlined
                  stack-label
                  label="Format: YYYY-MM-DD"
                  v-model="ayahDetail.tanggal_lahir"
                  class="fill col-6"
                >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        ref="qDateProxy"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date
                          v-model="ayahDetail.tanggal_lahir"
                          mask="YYYY-MM-DD"
                          @input="listTab[1].change = true"
                        >
                          <div class="row items-center justify-end">
                            <q-btn
                              v-close-popup
                              label="Close"
                              color="primary"
                              flat
                            />
                          </div>
                        </q-date>
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Kepemilikan Rumah</a>
                <q-input
                  @change="listTab[1].change = true"
                  outlined
                  v-model="ayahDetail.kepemilikan_rumah"
                  class="fill col-6"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Status Perkawinan</a>
                <q-input
                  @change="listTab[1].change = true"
                  outlined
                  v-model="ayahDetail.status_perkawinan"
                  class="fill col-6"
                ></q-input>
              </div>
            </q-card-section>
            <q-card-section class="column q-col-gutter-md q-pt-none">
              <p class="text-h6 text-grey q-mb-none">KONTAK :</p>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Alamat</a>
                <q-input
                  @change="listTab[1].change = true"
                  outlined
                  v-model="ayahDetail.alamat"
                  class="fill col-6"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">HP</a>
                <q-input
                  @change="listTab[1].change = true"
                  outlined
                  v-model="ayahDetail.hp"
                  class="fill col-6"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">E-mail</a>
                <q-input
                  @change="listTab[1].change = true"
                  outlined
                  v-model="ayahDetail.email"
                  class="fill col-6"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">FB</a>
                <q-input
                  @change="listTab[1].change = true"
                  outlined
                  v-model="ayahDetail.fb"
                  class="fill col-6"
                ></q-input>
              </div>
            </q-card-section>
            <q-card-section class="column q-col-gutter-md q-pt-none">
              <p class="text-h6 text-grey q-mb-none">KARIR :</p>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Pendidikan</a>
                <q-select
                  @input="listTab[1].change = true"
                  :options="poolPendidikan"
                  outlined
                  v-model="ayahDetail.pendidikan"
                  class="fill col-6"
                ></q-select>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Pekerjaan</a>
                <q-select
                  @input="listTab[1].change = true"
                  outlined
                  v-model="ayahDetail.pekerjaan"
                  :options="poolPekerjaan"
                  class="fill col-6"
                ></q-select>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Jabatan</a>
                <q-input
                  @change="listTab[1].change = true"
                  outlined
                  v-model="ayahDetail.jabatan"
                  class="fill col-6"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Tingkat Jabatan</a>
                <q-select
                  @input="listTab[1].change = true"
                  outlined
                  v-model="ayahDetail.tingkat_jabatan"
                  :options="poolJabatan"
                  class="fill col-6"
                ></q-select>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Penghasilan</a>
                <q-select
                  @input="listTab[1].change = true"
                  outlined
                  v-model="ayahDetail.penghasilan"
                  :options="gaji"
                  class="fill col-6"
                ></q-select>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Organisasi</a>
                <q-input
                  @change="listTab[1].change = true"
                  outlined
                  v-model="ayahDetail.organisasi"
                  class="fill col-6"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Jabatan Organinsasi</a>
                <q-input
                  @change="listTab[1].change = true"
                  outlined
                  v-model="ayahDetail.jabatan_organisasi"
                  class="fill col-6"
                ></q-input>
              </div>
              <p class="text-h6 text-grey q-mb-none">Status :</p>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Status</a>
                <q-select
                  @input="listTab[1].change = true"
                  map-options
                  emit-value
                  outlined
                  :options="optStatusOrtu"
                  v-model="ayahDetail.is_almarhum"
                  class="fill col-6"
                ></q-select>
              </div>
              <div
                class="row items-center justify-between"
                v-if="ayahDetail.is_almarhum == '1'"
              >
                <a class="item col-6 text-left">Tanggal Wafat</a>
                <q-input
                  @change="listTab[1].change = true"
                  outlined
                  stack-label
                  label="Format: YYYY-MM-DD"
                  v-model="ayahDetail.dod"
                  class="fill col-6"
                >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        ref="qDateProxy"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date
                          v-model="ayahDetail.dod"
                          mask="YYYY-MM-DD"
                          @input="listTab[1].change = true"
                        >
                          <div class="row items-center justify-end">
                            <q-btn
                              v-close-popup
                              label="Close"
                              color="primary"
                              flat
                            />
                          </div>
                        </q-date>
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </div>
            </q-card-section>
          </q-tab-panel>
          <q-tab-panel name="ibu">
            <q-card-section class="column q-col-gutter-md q-pt-none">
              <p class="text-h6 text-grey q-mb-none">GENERAL :</p>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Hubungan</a>
                <q-input
                  @change="listTab[2].change = true"
                  disable
                  outlined
                  v-model="ibuDetail.hubungan"
                  class="fill col-6"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Nama</a>
                <q-input
                  @change="listTab[2].change = true"
                  outlined
                  v-model="ibuDetail.nama"
                  class="fill col-6"
                  lazy-rules
                  :rules="[
                    (val) =>
                      (val && val.length > 0) || 'Nama Tidak Boleh Kosong',
                  ]"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">NIK</a>
                <q-input
                  @change="listTab[2].change = true"
                  outlined
                  v-model="ibuDetail.nik"
                  class="fill col-6"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Tempat Lahir</a>
                <q-input
                  @change="listTab[2].change = true"
                  outlined
                  v-model="ibuDetail.tempat_lahir"
                  class="fill col-6"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Tanggal Lahir</a>
                <q-input
                  @change="listTab[2].change = true"
                  outlined
                  v-model="ibuDetail.tanggal_lahir"
                  stack-label
                  label="Format: YYYY-MM-DD"
                  class="fill col-6"
                >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        ref="qDateProxy"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date
                          v-model="ibuDetail.tanggal_lahir"
                          mask="YYYY-MM-DD"
                        >
                          <div class="row items-center justify-end">
                            <q-btn
                              v-close-popup
                              label="Close"
                              color="primary"
                              flat
                            />
                          </div>
                        </q-date>
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Kepemilikan Rumah</a>
                <q-input
                  @change="listTab[2].change = true"
                  outlined
                  v-model="ibuDetail.kepemilikan_rumah"
                  class="fill col-6"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Status Perkawinan</a>
                <q-input
                  @change="listTab[2].change = true"
                  outlined
                  v-model="ibuDetail.status_perkawinan"
                  class="fill col-6"
                ></q-input>
              </div>
            </q-card-section>
            <q-card-section class="column q-col-gutter-md q-pt-none">
              <p class="text-h6 text-grey q-mb-none">KONTAK :</p>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Alamat</a>
                <q-input
                  @change="listTab[2].change = true"
                  outlined
                  v-model="ibuDetail.alamat"
                  class="fill col-6"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">HP</a>
                <q-input
                  @change="listTab[2].change = true"
                  outlined
                  v-model="ibuDetail.hp"
                  class="fill col-6"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">E-mail</a>
                <q-input
                  @change="listTab[2].change = true"
                  outlined
                  v-model="ibuDetail.email"
                  class="fill col-6"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">FB</a>
                <q-input
                  @change="listTab[2].change = true"
                  outlined
                  v-model="ibuDetail.fb"
                  class="fill col-6"
                ></q-input>
              </div>
            </q-card-section>
            <q-card-section class="column q-col-gutter-md q-pt-none">
              <p class="text-h6 text-grey q-mb-none">KARIR :</p>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Pendidikan</a>
                <q-select
                  @input="listTab[2].change = true"
                  outlined
                  :options="poolPendidikan"
                  v-model="ibuDetail.pendidikan"
                  class="fill col-6"
                ></q-select>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Pekerjaan</a>
                <q-select
                  @input="listTab[2].change = true"
                  outlined
                  :options="poolPekerjaan"
                  v-model="ibuDetail.pekerjaan"
                  class="fill col-6"
                ></q-select>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Jabatan</a>
                <q-input
                  @change="listTab[2].change = true"
                  outlined
                  v-model="ibuDetail.jabatan"
                  class="fill col-6"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Tingkat Jabatan</a>
                <q-select
                  @input="listTab[2].change = true"
                  outlined
                  v-model="ibuDetail.tingkat_jabatan"
                  :options="poolJabatan"
                  class="fill col-6"
                ></q-select>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Penghasilan</a>
                <q-select
                  @input="listTab[2].change = true"
                  outlined
                  v-model="ibuDetail.penghasilan"
                  :options="gaji"
                  class="fill col-6"
                ></q-select>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Organisasi</a>
                <q-input
                  @change="listTab[2].change = true"
                  outlined
                  v-model="ibuDetail.organisasi"
                  class="fill col-6"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Jabatan Organinsasi</a>
                <q-input
                  @change="listTab[2].change = true"
                  outlined
                  v-model="ibuDetail.jabatan_organisasi"
                  class="fill col-6"
                ></q-input>
              </div>
              <p class="text-h6 text-grey q-mb-none">Status :</p>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Status</a>
                <q-select
                  @input="listTab[2].change = true"
                  map-options
                  emit-value
                  outlined
                  :options="optStatusOrtu"
                  v-model="ibuDetail.is_almarhum"
                  class="fill col-6"
                ></q-select>
              </div>
              <div
                class="row items-center justify-between"
                v-if="ibuDetail.is_almarhum == '1'"
              >
                <a class="item col-6 text-left">Tanggal Wafat</a>
                <q-input
                  @change="listTab[2].change = true"
                  outlined
                  stack-label
                  label="Format: YYYY-MM-DD"
                  v-model="ibuDetail.dod"
                  class="fill col-6"
                >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        ref="qDateProxy"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date
                          v-model="ibuDetail.dod"
                          mask="YYYY-MM-DD"
                          @input="listTab[2].change = true"
                        >
                          <div class="row items-center justify-end">
                            <q-btn
                              v-close-popup
                              label="Close"
                              color="primary"
                              flat
                            />
                          </div>
                        </q-date>
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </div>
            </q-card-section>
          </q-tab-panel>
          <q-tab-panel name="wali">
            <q-card-section class="column q-col-gutter-md q-pt-none">
              <p class="text-h6 text-grey q-mb-none">GENERAL :</p>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Hubungan</a>
                <q-input
                  @change="listTab[3].change = true"
                  disable
                  outlined
                  v-model="waliDetail.hubungan"
                  class="fill col-6"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Nama</a>
                <q-input
                  @change="listTab[3].change = true"
                  outlined
                  v-model="waliDetail.nama"
                  class="fill col-6"
                  lazy-rules
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">NIK</a>
                <q-input
                  @change="listTab[3].change = true"
                  outlined
                  v-model="waliDetail.nik"
                  class="fill col-6"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Tempat Lahir</a>
                <q-input
                  @change="listTab[3].change = true"
                  outlined
                  v-model="waliDetail.tempat_lahir"
                  class="fill col-6"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Tanggal Lahir</a>
                <q-input
                  @change="listTab[3].change = true"
                  outlined
                  v-model="waliDetail.tanggal_lahir"
                  stack-label
                  label="Format: YYYY-MM-DD"
                  class="fill col-6"
                >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        ref="qDateProxy"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date
                          v-model="waliDetail.tanggal_lahir"
                          mask="YYYY-MM-DD"
                        >
                          <div class="row items-center justify-end">
                            <q-btn
                              v-close-popup
                              label="Close"
                              color="primary"
                              flat
                            />
                          </div>
                        </q-date>
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Kepemilikan Rumah</a>
                <q-input
                  @change="listTab[3].change = true"
                  outlined
                  v-model="waliDetail.kepemilikan_rumah"
                  class="fill col-6"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Status Perkawinan</a>
                <q-input
                  @change="listTab[3].change = true"
                  outlined
                  v-model="waliDetail.status_perkawinan"
                  class="fill col-6"
                ></q-input>
              </div>
            </q-card-section>
            <q-card-section class="column q-col-gutter-md q-pt-none">
              <p class="text-h6 text-grey q-mb-none">KONTAK :</p>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Alamat</a>
                <q-input
                  @change="listTab[3].change = true"
                  outlined
                  v-model="waliDetail.alamat"
                  class="fill col-6"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">HP</a>
                <q-input
                  @change="listTab[3].change = true"
                  outlined
                  v-model="waliDetail.hp"
                  class="fill col-6"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">E-mail</a>
                <q-input
                  @change="listTab[3].change = true"
                  outlined
                  v-model="waliDetail.email"
                  class="fill col-6"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">FB</a>
                <q-input
                  @change="listTab[3].change = true"
                  outlined
                  v-model="waliDetail.fb"
                  class="fill col-6"
                ></q-input>
              </div>
            </q-card-section>
            <q-card-section class="column q-col-gutter-md q-pt-none">
              <p class="text-h6 text-grey q-mb-none">KARIR :</p>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Pendidikan</a>
                <q-select
                  @input="listTab[3].change = true"
                  outlined
                  :options="poolPendidikan"
                  v-model="waliDetail.pendidikan"
                  class="fill col-6"
                ></q-select>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Pekerjaan</a>
                <q-select
                  @input="listTab[3].change = true"
                  outlined
                  :options="poolPekerjaan"
                  v-model="waliDetail.pekerjaan"
                  class="fill col-6"
                ></q-select>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Jabatan</a>
                <q-input
                  @change="listTab[3].change = true"
                  outlined
                  v-model="waliDetail.jabatan"
                  class="fill col-6"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Tingkat Jabatan</a>
                <q-select
                  @input="listTab[3].change = true"
                  outlined
                  v-model="waliDetail.tingkat_jabatan"
                  :options="poolJabatan"
                  class="fill col-6"
                ></q-select>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Penghasilan</a>
                <q-select
                  @input="listTab[3].change = true"
                  outlined
                  v-model="waliDetail.penghasilan"
                  :options="gaji"
                  class="fill col-6"
                ></q-select>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Organisasi</a>
                <q-input
                  @change="listTab[3].change = true"
                  outlined
                  v-model="waliDetail.organisasi"
                  class="fill col-6"
                ></q-input>
              </div>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Jabatan Organinsasi</a>
                <q-input
                  @change="listTab[3].change = true"
                  outlined
                  v-model="waliDetail.jabatan_organisasi"
                  class="fill col-6"
                ></q-input>
              </div>
              <p class="text-h6 text-grey q-mb-none">Status :</p>
              <div class="row items-center justify-between">
                <a class="item col-6 text-left">Status</a>
                <q-select
                  @input="listTab[3].change = true"
                  map-options
                  emit-value
                  outlined
                  :options="optStatusOrtu"
                  v-model="waliDetail.is_almarhum"
                  class="fill col-6"
                ></q-select>
              </div>
              <div
                class="row items-center justify-between"
                v-if="waliDetail.is_almarhum == '1'"
              >
                <a class="item col-6 text-left">Tanggal Wafat</a>
                <q-input
                  @change="listTab[3].change = true"
                  outlined
                  stack-label
                  label="Format: YYYY-MM-DD"
                  v-model="waliDetail.dod"
                  class="fill col-6"
                >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        ref="qDateProxy"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date
                          v-model="waliDetail.dod"
                          mask="YYYY-MM-DD"
                          @input="listTab[3].change = true"
                        >
                          <div class="row items-center justify-end">
                            <q-btn
                              v-close-popup
                              label="Close"
                              color="primary"
                              flat
                            />
                          </div>
                        </q-date>
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </div>
            </q-card-section>
          </q-tab-panel>
        </q-tab-panels>
        <q-card-actions class="row justify-between">
          <q-btn
            flat
            color="primary"
            label="Back"
            @click="$router.push('/siswalist/' + siswaDetail.jenjang)"
          ></q-btn>
          <q-btn
            outline
            label="MASUK KEMBALI"
            color="primary"
            v-can="['AKADEMIK', 'ADMIN']"
            v-if="siswaDetail.status == 'CUTI'"
            @click="backCuti = true"
          ></q-btn>
          <q-btn
            color="primary"
            v-if="$route.params.id > 0"
            type="submit"
            :disable="
              !listTab[0].change &&
                !listTab[1].change &&
                !listTab[2].change &&
                !listTab[3].change
            "
            v-can="['AKADEMIK', 'ADMIN']"
            label="simpan perubahan"
          ></q-btn>
          <q-btn
            color="primary"
            v-else
            :disable="
              !listTab[0].change &&
                !listTab[1].change &&
                !listTab[2].change &&
                !listTab[3].change
            "
            v-can="['AKADEMIK', 'ADMIN']"
            type="submit"
            label="Tambah Siswa"
          ></q-btn>
        </q-card-actions>
      </q-form>
    </q-card>
    <q-dialog v-model="confirm" persistent>
      <q-card>
        <q-card-section>
          Apa anda yakin akan menyimpan Data?
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="Tidak" color="primary" v-close-popup />
          <q-btn
            v-if="$route.params.id < 0"
            label="YA"
            v-can="['AKADEMIK', 'ADMIN']"
            @click="addNewSiswa()"
            color="primary"
            v-close-popup
          />
          <q-btn
            v-else
            label="YA"
            v-can="['AKADEMIK', 'ADMIN']"
            @click="updateperubahan()"
            color="primary"
            v-close-popup
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="backCuti" persistent v-if="siswaDetail.id">
      <q-card>
        <q-card-section>
          Apa anda ingin mengembalikan {{ siswaDetail.nama }} dari status CUTI?
          <br />
          Tulis "{{ siswaDetail.nama }}" tanpa tanda kutip untuk mengembalikan
          status siswa.

          <q-input outlined dense v-model="keyword"></q-input>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="Tidak" color="primary" v-close-popup />
          <q-btn
            :disable="keyword.toLowerCase() != siswaDetail.nama.toLowerCase()"
            label="YA"
            v-can="['AKADEMIK', 'ADMIN']"
            color="primary"
            @click="returnSiswa"
            v-close-popup
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      confirm: false,
      backCuti: false,
      keyword: "",
      selTab: "siswa",
      tingkat_smp: [7, 8, 9],
      tingkat_ma: [10, 11, 12],
      optTipe: [],

      optStatusOrtu: [
        { label: "Masih Hidup", value: "0" },
        { label: "Sudah Wafat", value: "1" },
      ],

      listTab: [
        { name: "siswa", label: "Siswa", change: false },
        { name: "ayah", label: "Ayah", change: false },
        { name: "ibu", label: "Ibu", change: false },
        { name: "wali", label: "Wali", change: false },
      ],
      minat: [null, "IPA", "IPS", "AGAMA"],
      gaji: [
        "Tidak Berpenghasilan",
        "Kurang dari Rp. 500,000",
        "Rp. 500,000 - Rp. 999,999",
        "Rp. 1,000,000 - Rp. 1,999,999",
        "Rp. 2,000,000 - Rp. 4,999,999",
        "Rp. 5,000,000 - Rp. 20,000,000",
        "Lebih dari Rp. 20,000,000",
      ],
      poolPekerjaan: [
        "Tidak bekerja",
        "Buruh",
        "Karyawan Swasta",
        "Lainnya",
        "Nelayan",
        "Pedagang Besar",
        "Pedagang Kecil",
        "Pensiunan",
        "Petani",
        "PNS/TNI/Polri",
        "Sudah Meninggal",
        "Wiraswasta",
        "Wirausaha",
        "Tidak dapat diterapkan",
      ],
      poolPendidikan: [
        "Tidak sekolah",
        "SD / sederajat",
        "SMP / sederajat",
        "SMA / sederajat",
        "D1",
        "D2",
        "D3",
        "D4",
        "S1",
        "S2",
        "S3",
      ],
      poolJabatan: [
        "Legislatif",
        "Eksekutif",
        "Kepala Daerah",
        "Birokrat",
        "Eselon 1",
        "Eselon 2",
        "Eselon 3",
        "Eselon 4",
        "BUMN",
        "Pengusaha",
        "PNS",
        "Lainya",
      ],
      siswaDetail: {
        jenjang: null,
        nama: null,
        nik: null,
        nisn: null,
        nipd: null,
        tempat_lahir: null,
        tanggal_lahir: null,
        gender: null,
        alamat: null,
        rt: null,
        rw: null,
        dusun: null,
        kelurahan: null,
        kecamatan: null,
        kode_pos: null,
        sekolah_asal: null,
        tipe_angkatan: null,
        tahun_masuk: null,
        jalur_sekolah_asal: null,
        anak_ke: null,
        bersaudara: null,
      },
      ayahDetail: {
        hubungan: "ayah",
        nama: null,
        nik: null,
        tempat_lahir: null,
        tanggal_lahir: null,
        kepemilikan_rumah: null,
        status_perkawinan: null,
        alamat: null,
        hp: null,
        email: null,
        fb: null,
        pendidikan: null,
        perkerjaan: null,
        jabatan: null,
        penghasilan: null,
        organisasi: null,
        jabatan_organisasi: null,
      },
      ibuDetail: {
        hubungan: "ibu",
        nama: null,
        nik: null,
        tempat_lahir: null,
        tanggal_lahir: null,
        kepemilikan_rumah: null,
        status_perkawinan: null,
        alamat: null,
        hp: null,
        email: null,
        fb: null,
        pendidikan: null,
        perkerjaan: null,
        jabatan: null,
        penghasilan: null,
        organisasi: null,
        jabatan_organisasi: null,
      },
      waliDetail: {
        hubungan: "wali",
        nama: null,
        nik: null,
        tempat_lahir: null,
        tanggal_lahir: null,
        kepemilikan_rumah: null,
        status_perkawinan: null,
        alamat: null,
        hp: null,
        email: null,
        fb: null,
        pendidikan: null,
        perkerjaan: null,
        jabatan: null,
        penghasilan: null,
        organisasi: null,
        jabatan_organisasi: null,
      },
    };
  },
  async mounted() {
    this.$q.loading.show({ message: "Mohon Tunggu..." });
    this.optTipe = ["LANJUTAN SD", "BARU"];
    if (this.$route.params.jenjang == "MA") {
      this.optTipe = ["LANJUTAN SMP", "BARU"];
    }
    if (this.$route.params.id > 0) {
      await this.getSiswaDetail();
    } else {
      this.siswaDetail.jenjang = this.$route.params.jenjang;
    }
    this.$q.loading.hide();
  },
  methods: {
    async returnSiswa() {
      await this.$http.put(`/siswa/list/backcuti/${this.$route.params.id}`);
      this.$q.notify({
        message: "Data Siswa Telah Dikembalikan Dari Status Cuti!",
        color: "positive",
      });
      await this.getSiswaDetail();
    },
    async addNewSiswa() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp_from_addsiswa = await this.$http.post(
        `/siswa/list/addsiswa`,
        this.siswaDetail
      );
      let id_input_siswa = resp_from_addsiswa.data;
      this.siswaDetail.tanggal_lahir = moment(
        this.siswaDetail.tanggal_lahir
      ).format("YYYY-MM-DD");
      this.ayahDetail.tanggal_lahir = moment(
        this.ayahDetail.tanggal_lahir
      ).format("YYYY-MM-DD");
      this.ibuDetail.tanggal_lahir = moment(
        this.ibuDetail.tanggal_lahir
      ).format("YYYY-MM-DD");
      this.waliDetail.tanggal_lahir = moment(
        this.waliDetail.tanggal_lahir
      ).format("YYYY-MM-DD");
      await this.$http.post(
        `keluarga/list/addkeluarga/ayah/${id_input_siswa}`,
        this.ayahDetail
      );
      await this.$http.post(
        `keluarga/list/addkeluarga/ibu/${id_input_siswa}`,
        this.ibuDetail
      );
      await this.$http.post(
        `keluarga/list/addkeluarga/wali/${id_input_siswa}`,
        this.waliDetail
      );
      this.$q.loading.hide();
      this.$router.push("/siswalist/" + this.siswaDetail.jenjang);
    },
    async getSiswaDetail() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http
        .get(
          "/siswa/list/detail/" + this.$route.params.id,
          this.$hlp.getHeader()
        )
        .then((resp) => {
          this.siswaDetail = resp.data;
        });
      await this.$http
        .get(
          "/keluarga/list/detail/" + this.$route.params.id + "/ayah",
          this.$hlp.getHeader()
        )
        .then((resp) => {
          this.ayahDetail = resp.data;
        });
      await this.$http
        .get(
          "/keluarga/list/detail/" + this.$route.params.id + "/ibu",
          this.$hlp.getHeader()
        )
        .then((resp) => {
          this.ibuDetail = resp.data;
        });
      await this.$http
        .get(
          "/keluarga/list/detail/" + this.$route.params.id + "/wali",
          this.$hlp.getHeader()
        )
        .then((resp) => {
          this.waliDetail = resp.data;
        });
      this.$q.loading.hide();
    },
    async updateperubahan() {
      this.$q.loading.show({ message: "Meng-update Data" });
      if (this.listTab[0].change) {
        this.siswaDetail.tanggal_lahir = moment(
          this.siswaDetail.tanggal_lahir
        ).format("YYYY-MM-DD");
        await this.$http
          .put(
            "/siswa/list/update/" + this.$route.params.id,
            this.siswaDetail,
            this.$hlp.getHeader()
          )
          .then((resp) => {});
      }
      if (this.listTab[1].change) {
        console.log(this.ayahDetail);
        this.ayahDetail.tanggal_lahir = moment(
          this.ayahDetail.tanggal_lahir
        ).format("YYYY-MM-DD");
        console.log(this.ayahDetail);
        await this.$http.put(
          "/keluarga/list/update/ayah/" + this.$route.params.id,
          this.ayahDetail,
          this.$hlp.getHeader()
        );
      }
      if (this.listTab[2].change) {
        this.ibuDetail.tanggal_lahir = moment(
          this.ibuDetail.tanggal_lahir
        ).format("YYYY-MM-DD");
        await this.$http
          .put(
            "/keluarga/list/update/ibu/" + this.$route.params.id,
            this.ibuDetail,
            this.$hlp.getHeader()
          )
          .then((resp) => {});
      }
      if (this.listTab[3].change) {
        this.waliDetail.tanggal_lahir = moment(
          this.waliDetail.tanggal_lahir
        ).format("YYYY-MM-DD");
        await this.$http
          .put(
            "/keluarga/list/update/wali/" + this.$route.params.id,
            this.waliDetail,
            this.$hlp.getHeader()
          )
          .then((resp) => {});
      }
      this.$q.loading.hide();
      this.$router.push("/siswalist/" + this.$route.params.jenjang);
    },
  },
};
</script>

<style lang="scss" scoped></style>
